<template>
  <div>
    <b-card
      title="กราฟสมาชิก"
      no-body
    >
      <b-card-body>
        <app-echart-stacked-area :option-data="graphOption" />
        <br>
        <table style="width: 100%;">
          <thead>
            <th
              style="text-align: center;"
            >รายการ \ วันที่
            </th>
            <th
              v-for="graphdate in graphOption.xAxisData"
              :key="graphdate"
              style="text-align: center;"
            >{{ graphdate }}
            </th>
          </thead>
          <tbody>
            <tr>
              <td>สมัครใหม่</td>
              <td
                :key="gdata"
                v-for="gdata in (graphOption.series[0].data || [])"
                style="text-align: center;"
              >{{ gdata }}</td>
            </tr>
            <tr>
              <td>สมัครใหม่เติมเงิน</td>
              <td
                :key="gdata"
                v-for="gdata in (graphOption.series[1].data || [])"
                style="text-align: center;"
              >{{ gdata }}</td>
            </tr>
            <tr>
              <td>เข้าเล่น</td>
              <td
                :key="gdata"
                v-for="gdata in (graphOption.series[2].data || [])"
                style="text-align: center;"
              >{{ gdata }}</td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>
    <b-card
      title="รายชื่อสมาชิก"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="วันที่"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMemberStat"
              >
                ดูรายงาน
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="สมัครใหม่" active>
          <template #title>
            สมัครใหม่<b-badge variant="success" style="margin-left: 10px;"> {{ new_members.length }} </b-badge>
          </template>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel('new_members')"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="newMemberTable"
            striped
            hover
            responsive
            class="position-relative"
            :items="new_members"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.phoneno }}<br>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ `${data.item.fname} ${data.item.lname}` }}
            </template>
            <template #cell(bank_acc_no)="data">
              <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
            </template>
            <template #cell(credit)="data">
              <div style="text-align: right;">
                {{ `${data.item.wallet.credit}` }}
              </div>
            </template>
            <template #cell(get_promo)="data">
              <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
                {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
              </b-badge>
            </template>
            <template #cell(invited_by)="data">
              <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                {{ data.item.refer_by.phoneno }}<br>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="data">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="viewMember(data.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  แก้ไขข้อมูล
                </b-button>
              </div>
            </template>

          </b-table>
        </b-tab>
        <b-tab title="สมัครใหม่เติมเงิน">
          <template #title>
            สมัครใหม่เติมเงิน<b-badge variant="success" style="margin-left: 10px;"> {{ walkin_members.length }} </b-badge>
          </template>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel('walkin_members')"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="walkinMembers"
            striped
            hover
            responsive
            class="position-relative"
            :items="walkin_members"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.phoneno }}<br>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ `${data.item.fname} ${data.item.lname}` }}
            </template>
            <template #cell(bank_acc_no)="data">
              <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
            </template>
            <template #cell(credit)="data">
              <div style="text-align: right;">
                {{ `${data.item.wallet.credit}` }}
              </div>
            </template>
            <template #cell(get_promo)="data">
              <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
                {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
              </b-badge>
            </template>
            <template #cell(invited_by)="data">
              <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                {{ data.item.refer_by.phoneno }}<br>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="data">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="viewMember(data.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  แก้ไขข้อมูล
                </b-button>
              </div>
            </template>

          </b-table>
        </b-tab>
        <b-tab title="เข้าเล่น">
          <template #title>
            เข้าเล่น<b-badge variant="success" style="margin-left: 10px;"> {{ active_members.length }} </b-badge>
          </template>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel('active_members')"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="activeMembers"
            striped
            hover
            responsive
            class="position-relative"
            :items="active_members"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.phoneno }}<br>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ `${data.item.fname} ${data.item.lname}` }}
            </template>
            <template #cell(bank_acc_no)="data">
              <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
            </template>
            <template #cell(credit)="data">
              <div style="text-align: right;">
                {{ `${data.item.wallet.credit}` }}
              </div>
            </template>
            <template #cell(get_promo)="data">
              <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
                {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
              </b-badge>
            </template>
            <template #cell(invited_by)="data">
              <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                {{ data.item.refer_by.phoneno }}<br>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="data">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="viewMember(data.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  แก้ไขข้อมูล
                </b-button>
              </div>
            </template>

          </b-table>
        </b-tab>
        <b-tab
          title="ไม่เข้าเล่น"
          @click="fetchMemberReport('not-active')"
        >
          <template #title>
            ไม่เข้าเล่น<b-badge variant="success" style="margin-left: 10px;"> {{ inactive_members.length }} </b-badge>
          </template>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel('inactive_members')"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="inactiveMembers"
            striped
            hover
            responsive
            class="position-relative"
            :items="inactive_members"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.phoneno }}<br>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ `${data.item.fname} ${data.item.lname}` }}
            </template>
            <template #cell(bank_acc_no)="data">
              <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
            </template>
            <template #cell(credit)="data">
              <div style="text-align: right;">
                {{ `${data.item.wallet.credit}` }}
              </div>
            </template>
            <template #cell(get_promo)="data">
              <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
                {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
              </b-badge>
            </template>
            <template #cell(invited_by)="data">
              <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                {{ data.item.refer_by.phoneno }}<br>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="data">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="viewMember(data.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  แก้ไขข้อมูล
                </b-button>
              </div>
            </template>

          </b-table>
        </b-tab>
        <b-tab
          title="ลูกค้าเก่าเติมเงินครั้งแรก"
          @click="fetchMemberReport('first-topup')"
        >
          <template #title>
            ลูกค้าเก่าเติมเงินครั้งแรก<b-badge variant="success" style="margin-left: 10px;" v-if="firsttopup_members.length > 0"> {{ firsttopup_members.length }} </b-badge>
          </template>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel('firsttopup_members')"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="firstTopupMembers"
            striped
            hover
            responsive
            class="position-relative"
            :items="firsttopup_members"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.phoneno }}<br>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ `${data.item.fname} ${data.item.lname}` }}
            </template>
            <template #cell(bank_acc_no)="data">
              <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
            </template>
            <template #cell(credit)="data">
              <div style="text-align: right;">
                {{ `${data.item.wallet.credit}` }}
              </div>
            </template>
            <template #cell(get_promo)="data">
              <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
                {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
              </b-badge>
            </template>
            <template #cell(invited_by)="data">
              <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                {{ data.item.refer_by.phoneno }}<br>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="data">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="viewMember(data.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  แก้ไขข้อมูล
                </b-button>
              </div>
            </template>

          </b-table>
        </b-tab>
        <b-tab
          title="ลูกค้าเก่ากลับมาเล่น"
          @click="fetchMemberReport('comeback')"
        >
          <template #title>
            ลูกค้าเก่ากลับมาเล่น<b-badge variant="success" style="margin-left: 10px;" v-if="comeback_members.length > 0"> {{ comeback_members.length }} </b-badge>
          </template>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 mb-1"
                @click="saveToExcel('comeback_members')"
              >
                ดึง Excel
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="combackMembersTable"
            striped
            hover
            responsive
            class="position-relative"
            :items="comeback_members"
            :fields="fields"
          >
            <template #cell(username)="data">
              {{ data.item.phoneno }}<br>
              {{ data.item.username }}
            </template>
            <template #cell(fullname)="data">
              {{ `${data.item.fname} ${data.item.lname}` }}
            </template>
            <template #cell(bank_acc_no)="data">
              <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ `${data.item.bank_acc_no}` }}
            </template>
            <template #cell(credit)="data">
              <div style="text-align: right;">
                {{ `${data.item.wallet.credit}` }}
              </div>
            </template>
            <template #cell(get_promo)="data">
              <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
                {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
              </b-badge>
            </template>
            <template #cell(invited_by)="data">
              <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
                ไม่มี
              </b-badge>
              <div v-if="data.item.invited_by">
                {{ data.item.refer_by.phoneno }}<br>
                {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
              </div>
            </template>
            <template #cell(status)="data">
              <div style="text-align:center;">
                <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
                  {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
                </b-badge><br>
              </div>
            </template>
            <template #cell(btn_detail)="data">
              <div style="text-align:center;">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="viewMember(data.item)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  แก้ไขข้อมูล
                </b-button>
              </div>
            </template>

          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BRow, BCol, BForm, BFormInput, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'

export default {
  components: {
    AppEchartStackedArea,
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // Cleave,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // vSelect,
    flatPickr,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      new_members: [],
      walkin_members: [],
      active_members: [],
      comeback_members: [],
      firsttopup_members: [],
      inactive_members: [],
      memberGraph: {},
      graphOption: {
        xAxisData: [],
        series: [
          {
            name: 'สมัครใหม่',
            type: 'line',
            data: [],
          },
          {
            name: 'สมัครใหม่เติมเงิน',
            type: 'line',
            // stack: 'Total',
            // showSymbol: false,
            // areaStyle: {},
            // lineStyle: {
            //   width: 0,
            // },
            data: [],
          },
          {
            name: 'เข้าเล่น',
            type: 'line',
            // stack: 'Total',
            // showSymbol: true,
            // areaStyle: {},
            // lineStyle: {
            //   width: 0,
            // },
            data: [],
          },
        ],
      },
      tableQuery: {
        page: 1,
        page_size: 100,
        startdate: moment().format('YYYY-MM-DD'),
      },
      fields: [
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'bank_acc_no', label: 'เลขที่บัญชี' },
        // { key: 'credit', label: 'ยอดเครดิต' },
        { key: 'get_promo', label: 'รับโปร' },
        // { key: 'invited_by', label: 'คนชวน' },
        { key: 'created_at', label: 'สมัครเมื่อ', formatter: val => this.formatDate(moment(val)) },
        { key: 'updated_at', label: 'เข้าเล่นล่าสุด', formatter: val => this.formatDate(moment(val)) },
        { key: 'status', label: 'สถานะ' },
        { key: 'subscription', label: 'ช่องทาง' },
        // { key: 'afflink', label: 'ลิ้งชวนเพื่อน' },
        // { key: 'btn_credit', label: 'อัพเดดเครดิต' },
        // { key: 'btn_detail', label: 'แก้ไขข้อมูล' },
      ],
    }
  },
  computed: {
  },
  mounted() {
    // Set the initial number of items
    this.searchMemberStat()
    this.fetchMemberReport()
    this.fetchMemberGraph()
  },
  methods: {
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    saveToExcel(dataToExports) {
      import('@/libs/Export2Excel').then(excel => {
        let list = []
        if (dataToExports === 'new_members')
          list = this.new_members
        else if (dataToExports === 'walkin_members')
          list = this.walkin_members
        else if (dataToExports === 'active_members')
          list = this.active_members
        else if (dataToExports === 'firsttopup_members')
          list = this.firsttopup_members
        else if (dataToExports === 'comeback_members')
          list = this.comeback_members
        else if (dataToExports === 'inactive_members')
          list = this.inactive_members
        const headerTitle = ['เบอร์โทร', 'ยูสเซอร์', 'ชื่อ', 'นามสกุล', 'รหัสธนาคาร', 'เลขที่บัญชี', 'วันที่สมัคร', 'เข้าเล่นล่าสุด']
        const headerVal = ['phoneno', 'username', 'fname', 'lname', 'bank_code', 'bank_acc_no', 'created_at', 'updated_at']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: `${dataToExports}-${this.tableQuery.startdate}`,
          autoWidth: true,
          bookType: 'xlsx',
        })
        this.clearFields()
      })
    },
    searchMemberStat() {
      this.new_members = []
      this.walkin_members = []
      this.active_members = []
      this.$http.post(`report/member-stats?date=${this.tableQuery.startdate}`).then(({
        data: {
          success, newMembers, walkinMembers, activeMembers,
        },
      }) => {
        if (success) {
          this.new_members = newMembers
          this.walkin_members = walkinMembers
          this.active_members = activeMembers
        }
      })
      // this.$refs.membertable.refresh()
    },
    fetchMemberReport(mode) {
      this.$http.post(`report/member-stats?date=${this.tableQuery.startdate}&mode=${mode}`).then(({
        data: {
          success, members,
        },
      }) => {
        if (success) {
          if (mode === 'first-topup')
            this.firsttopup_members = members
          else if (mode === 'comeback')
            this.comeback_members = members
          else if (mode === 'not-active')
            this.inactive_members = members
        }
      })
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      // this.$refs.membertable.refresh()
    },
    fetchMemberGraph() {
      this.$http.post('report/member-stats-graph').then(({
        data: {
          success, memberStats, activeMembers, newMembers, activeNewMembers,
        },
      }) => {
        this.graphOption.series[0].data = []
        this.graphOption.series[1].data = []
        this.graphOption.series[2].data = []

        if (success) {
          this.memberGraph.memberStats = memberStats
          this.memberGraph.activeMembers = activeMembers
          this.memberGraph.newMembers = newMembers
          this.memberGraph.activeNewMembers = activeNewMembers
          this.graphOption.series[0].data = [...newMembers]
          this.graphOption.series[1].data = [...activeNewMembers]
          this.graphOption.series[2].data = [...activeMembers]
        }
        for (let ix = 1; ix <= parseInt(moment().format('DD')); ix += 1) {
          this.graphOption.xAxisData.push(`${ix}/${moment().format('MM')}`)
          if (!success) {
            this.graphOption.series[0].data.push(0)
            this.graphOption.series[1].data.push(0)
            this.graphOption.series[2].data.push(0)
          }
        }

        console.log('graphOption', this.graphOption)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
